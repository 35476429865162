import React, { PropsWithChildren, useMemo } from 'react'
// Types
import { SectionBaseProps, SectionVideo } from '../../../types/sections'
// Components
import { Grid, Row } from 'react-styled-flexboxgrid'
import { Flex, Box, Button, Heading, VideoElement, Text } from '../../../atoms'
import ContentMd from '../../commons/ContentMd'
import { BoxWithDot } from '../FeatureSimple/FeatureSimple.styled'

export interface VideoProps extends SectionBaseProps {
  data: SectionVideo
}

const Video: React.FC<VideoProps> = ({ data }) => {
  const { id, sectionId, headline, content, button, video, videoOnLeft, backgroundColor, whiteText } = data

  const textColor = whiteText ? 'white' : 'dark'

  const renderers = useMemo(() => {
    return {
      li: (props: PropsWithChildren<any>) => {
        return (
          <BoxWithDot
            as={'li'}
            position={'relative'}
            my={4}
            dotColor={'purpleBlue'}
            ordered={props.ordered}
            pl={'36px'}
          >
            <Text as={'span'} fontSize={2} lineHeight={1.5} {...props} color={'dark'} />
          </BoxWithDot>
        )
      },
    }
  }, [])

  return (
    <Box
      as={'section'}
      id={sectionId || id}
      className={'p-section'}
      bg={backgroundColor?.hex || 'white'}
      my={backgroundColor ? undefined : [80, 80, 120]}
      py={backgroundColor ? [80, 64, 120] : undefined}
    >
      <Grid>
        <Row center={'xs'} middle={'xs'} reverse={videoOnLeft}>
          <Box flex={'1'} px={18}>
            <Box pr={!videoOnLeft ? [0, 0, 10] : undefined} pl={videoOnLeft ? [0, 0, 10] : undefined}>
              {!!headline && (
                <Heading as={'h4'} color={textColor}>
                  {headline}
                </Heading>
              )}
              {!!content && (
                <Box mt={5}>
                  <ContentMd content={content} renderers={renderers} color={textColor} />
                </Box>
              )}
              {!!button && (
                <Flex mt={6}>
                  <Box flex={['0 0 100%', '0 0 auto']}>
                    <Button fluid {...button} />
                  </Box>
                </Flex>
              )}
            </Box>
          </Box>
          <Box flex={['0 0 100%', '0 0 100%', '0 0 50%', '0 0 654px']} px={18}>
            <Box mt={[6, 6, 0]}>
              <VideoElement {...video} />
            </Box>
          </Box>
        </Row>
      </Grid>
    </Box>
  )
}

export default Video
